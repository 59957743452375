module.exports = {
  collapse: (shouldCollapse) => {
    if (shouldCollapse) {
      document.getElementById("basic").classList.remove('isHide');
      document.getElementById("sns").classList.add('isHide');
    }
    else {
      document.getElementById("basic").classList.add('isHide');
      document.getElementById("sns").classList.remove('isHide');
    }
  },

  onFocus: (id) => {
    var ele = document.getElementById(id);
    ele.classList.remove('isHide');
  },

  onFocusout: (id) => {
    var ele = document.getElementById(id);
    setTimeout(function() {
      ele.classList.add('isHide');
    }, 200)
  },

  onClearInput: (id) => {
    var ele = document.getElementById(id);
    ele.value = '';
  },

  setCookie: (cname, cvalue, expireTime) => {
    const d = new Date();
    d.setTime(d.getTime() + expireTime);
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  },

  getCookie: (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },

  reportFormValidity: (form) => {
    if (HTMLFormElement.prototype.reportValidity) {
      return form.reportValidity();
    }
    return form.checkValidity();
  }
}
